import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
	CircularProgress,
	Card,
	Button,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Divider,
	CardActions
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// import md5 from "blueimp-md5";
//cmp
import Na from "../na";
import RowEntry from "../row-entry";
// services
import Gupport from "../../services/gupport";
// import { merge } from "../../services/utils";

class UserGeneralInfo extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			error: null,
			user: null,
			// inputErrors: {},
			saveButtonDisabled: true,
			// resetButtonDisabled: true,
			saving: false,
			// resettingPwd: false,
			openErrorDialog: false,
			// openResetDialog: false,
			dialogErrorMsg: null
			// password: null,
			// passwordConfirm: null
		};

		this.refresh = this.refresh.bind(this);
		// this.handleSelectChanged = this.handleSelectChanged.bind(this);
		this.handleFieldChanged = this.handleFieldChanged.bind(this);
		this.handleSaveClick = this.handleSaveClick.bind(this);
		// this.handleResetPasswordMailClick = this.handleResetPasswordMailClick.bind(this);
		// this.showResetDialog = this.showResetDialog.bind(this);
		// this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
		// this.handlePasswordConfirmChanged = this.handlePasswordConfirmChanged.bind(this);
		// this.handleResetPasswordClick = this.handleResetPasswordClick.bind(this);
		this.handleCloseClick = this.handleCloseClick.bind(this);
		this.handleFetchUserResult = this.handleFetchUserResult.bind(this);
	}

	componentDidMount() {
		this.fetchUser(this.props.userId);
	}

	refresh() {
		this.fetchUser(this.props.userId);
	}

	handleFetchUserResult(error, msg) {
		if (error || msg.payload.status === "error") {
			this.setState({
				loading: false,
				error: error || msg.payload.data,
				user: null
			});
		} else {
			this.setState({
				loading: false,
				error: error,
				user: msg.payload.data
			});
		}
	}

	fetchUser(userId) {
		if (this.props.isAdvancedSearchEnabled) {
			Gupport.getB2CUser({
				sub: userId
			}, this.handleFetchUserResult);
		} else {
			Gupport.getUser({
				username: userId
			}, this.handleFetchUserResult);
		}
	}

	// handleSelectChanged(event) {
	// 	this.setState((prevState) => ({
	// 		user: merge(prevState.user, { gender: event.target.value })
	// 	}), () => {
	// 		this.checkInputFields();
	// 	});
	// }

	handleFieldChanged(event) {
		const name = event.target.name;
		const value = event.target.value;
		this.setState((prevState) => {
			const user = structuredClone(prevState.user);
			switch (name) {
				case "phone.country_code":
					user.phone.country_code = value;
					break;
				case "phone.number":
					user.phone.number = value;
					break;
				default:
					user[name] = value;
			}
			return {
				user: user
			};
		}, () => {
			this.checkInputFields();
		});
	}

	checkInputFields() {
		const { t } = this.props;
		const inputErrors = {};
		let saveButtonDisabled = false;
		if (this.state.user.name.trim()) {
			inputErrors.name = null;
		} else {
			inputErrors.name = t("messages.required");
			saveButtonDisabled = true;
		}
		this.setState({
			// inputErrors: inputErrors,
			saveButtonDisabled: saveButtonDisabled
		});
	}

	handleSaveClick() {
		const { t } = this.props;
		this.setState({
			saveButtonDisabled: true,
			saving: true
		});
		Gupport.saveUser(this.state.user, (error, msg) => {
			if (error || msg.payload.status === "error") {
				this.setState({
					openErrorDialog: true,
					dialogErrorMsg: t("users.notsaved"),
					saving: false
				});
			} else {
				this.setState({
					saving: false
				});
			}
		});
	}

	// handleResetPasswordMailClick() {
	//	const { t } = this.props;

	// 	// this.setState({
	// 	// 	resetting: true
	// 	// });
	// 	Gupport.sendUserPwdReset({
	// 		username: this.props.userId
	// 	}, (error, msg) => {
	// 		if (error || msg.payload.status === "error") {
	// 			this.setState({
	// 				openErrorDialog: true,
	// 				dialogErrorMsg: t("users.resetMailMsg"),
	// 				saving: false
	// 			});
	// 		}// else {
	// 		// this.setState({
	// 		// 	resetting: false
	// 		// });
	// 		//}
	// 	});
	// }

	// showResetDialog() {
	// 	this.setState({
	// 		resetButtonDisabled: true,
	// 		openResetDialog: true,
	// 		password: null,
	// 		passwordConfirm: null
	// 	});
	// }

	// handlePasswordChanged(event) {
	// 	this.setState({
	// 		password: event.target.value
	// 	}, () => {
	// 		this.checkPasswords();
	// 	});
	// }

	// handlePasswordConfirmChanged(event) {
	// 	this.setState({
	// 		passwordConfirm: event.target.value
	// 	}, () => {
	// 		this.checkPasswords();
	// 	});
	// }

	// checkPasswords() {
	// 	this.setState({
	// 		resetButtonDisabled: !this.state.password || !this.state.passwordConfirm || this.state.password !== this.state.passwordConfirm
	// 	});
	// }

	// handleResetPasswordClick() {
	//	const { t } = this.props;

	// 	this.setState({
	// 		resettingPwd: true
	// 	});
	// 	Gupport.setUserPwd({
	// 		username: this.props.userId,
	// 		password: md5(this.state.password)
	// 	}, (error, msg) => {
	// 		if (error || msg.payload.status === "error") {
	// 			this.setState({
	// 				openErrorDialog: true,
	// 				dialogErrorMsg: t("users.resetFailedMsg"),
	// 				openResetDialog: false,
	// 				saving: false,
	// 				resettingPwd: false
	// 			});
	// 		} else {
	// 			this.setState({
	// 				openResetDialog: false,
	// 				resettingPwd: false
	// 			});
	// 		}
	// 	});
	// }

	handleCloseClick() {
		this.setState({
			openErrorDialog: false,
			dialogErrorMsg: null
			// openResetDialog: false,
			// password: null,
			// passwordConfirm: null
		});
	}

	getEmail(user) {
		if (user.email) {
			return user.email;
		} else if (user.emails) {
			return user.emails.join(", ");
		} else {
			return user.username;
		}
	}

	getCreated(created) {
		if (!created) {
			return <Na />;
		}
		const date = (typeof created === "number") ? new Date(created / 1000) : new Date(created);
		return <time dateTime={date.toISOString()}>{date.toLocaleString()}</time>;
	}

	render() {
		const { t } = this.props;

		if (this.state.loading) {
			return <CircularProgress />;
		}
		if (this.state.error) {
			return <div>{this.state.error}</div>;
		}

		const isReadOnly = !Gupport.saveUser;
		// const isSendPwd = Boolean(Gupport.setUserPwd);
		// const isSendUserPwdReset = Boolean(Gupport.sendUserPwdReset);

		return (
			<Card className="card-separator card-info">
				<RowEntry title={t("users.username")}>
					{this.props.userId}
				</RowEntry>
				<Divider />
				<RowEntry title={t("users.email")}>
					{this.getEmail(this.state.user)}
				</RowEntry>
				<Divider />
				<RowEntry title={t("users.name")} styleRight={{ margin: "-8px 0" }}>
					{isReadOnly ? (this.state.user.name || <Na />) :
						<TextField
							name="name"
							placeholder={t("users.name")}
							fullWidth={true}
							value={this.state.user.name}
							onChange={this.handleFieldChanged}
						/>
					}
				</RowEntry>
				<Divider />
				<RowEntry title={t("users.givenName")}>
					{this.state.user.given_name || <Na />}
				</RowEntry>
				<Divider />
				<RowEntry title={t("users.familyName")}>
					{this.state.user.family_name || <Na />}
				</RowEntry>
				<Divider />
				<RowEntry title={t("users.created")}>
					{this.getCreated(this.state.user.created)}
				</RowEntry>
				<Divider />
				<RowEntry title={t("users.country")}>
					{this.state.user.country || <Na />}
				</RowEntry>
				<Divider />
				{/*<TableRow>
							<TableCell style={columnStyle}>{t("users.gender")}</TableCell>
							<TableCell>
								{
									isReadOnly
										? this.state.user.gender || <Na />
										: <Select
											name="gender"
											value={this.state.user.gender}
											onChange={this.handleSelectChanged}
										>
											<MenuItem value="" primaryText="" />
											<MenuItem value="male" primaryText={t("users.male")} />
											<MenuItem value="female" primaryText={t("users.female")} />
										</Select>
								}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={columnStyle}>{t("users.phone")}</TableCell>
							<TableCell>
								{
									isReadOnly
										? this.state.user.phone ? `${this.state.user.phone.country_code }${this.state.user.phone.number} : <Na />
										: <>
											<TextField
												name="phone.country_code"
												placeholder={t("users.countryCode")}
												fullWidth={false}
												value={this.state.user.phone ? this.state.user.phone.country_code : null}
												onChange={this.handleFieldChanged}
												style={{width: "30%"}}
											/>
											<TextField
												name="phone.number"
												placeholder={t("users.number")}
												fullWidth={false}
												value={this.state.user.phone ? this.state.user.phone.number : null}
												onChange={this.handleFieldChanged}
											/>
										</>
								}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={columnStyle}>{t("users.city")}</TableCell>
							<TableCell>
								{
									isReadOnly
										? this.state.user.city || <Na />
										: <TextField
											name="city"
											placeholder={t("users.city")}
											fullWidth={true}
											value={this.state.user.city}
											onChange={this.handleFieldChanged}
											//errorText={this.state.inputErrors.city}
										/>
								}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={columnStyle}>{t("users.region")}</TableCell>
							<TableCell>
								{
									isReadOnly
										? this.state.user.region || <Na />
										: <TextField
											name="region"
											placeholder={t("users.region")}
											fullWidth={true}
											value={this.state.user.region}
											onChange={this.handleFieldChanged}
										/>
								}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={columnStyle}>{t("users.country")}</TableCell>
							<TableCell>
								{
									isReadOnly
										? this.state.user.country || <Na />
										: <TextField
											name="country"
											placeholder={t("users.country")}
											fullWidth={true}
											value={this.state.user.country}
											onChange={this.handleFieldChanged}
										/>
								}
							</TableCell>
						</TableRow>*/}
				<CardActions style={{ float: "right" }}>
					{!isReadOnly &&
						<LoadingButton
							variant="contained"
							disabled={this.state.saveButtonDisabled}
							loading={this.state.saving}
							onClick={this.handleSaveClick}
						>
							{t("messages.save")}
						</LoadingButton>
					}
				</CardActions>
				{/*<TableRow>
							<TableCell style={columnStyle}></TableCell>
							<TableCell></TableCell>
						</TableRow>
						{(isSendUserPwdReset || isSendPwd) && <TableRow>
							<TableCell style={columnStyle}>{t("users.password")}</TableCell>
							<TableCell>
								{isSendUserPwdReset &&
								<RaisedButton label={t("users.resetMail")} primary={true} onClick={this.handleResetPasswordMailClick} />
								}
								{isSendPwd &&
									<LoadingButton
										variant="contained"
										loading={this.state.resetting}
										onClick={this.showResetDialog}
										style={{marginLeft: isSendUserPwdReset ? "10px" : 0}}
									>
										{t("users.resetPwd")}
									</LoadingButton>
								}
							</TableCell>
						</TableRow>*/}
				<Dialog
					fullWidth={true}
					open={this.state.openErrorDialog}
					onClose={this.handleCloseClick}
				>
					<DialogTitle>{t("dialog.error")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{this.state.dialogErrorMsg}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleCloseClick}>{t("dialog.ok")}</Button>
					</DialogActions>
				</Dialog>
				{/* <Dialog
					fullWidth={true}
					open={this.state.openResetDialog}
					onClose={this.handleCloseClick}
				>
					<DialogTitle>{t("users.resetPwdDialogTitle")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{t("users.resetDialogMsg")} {this.state.user.name} ({this.props.userId}):
							<TextField
								type="password"
								placeholder={t("users.newPwd")}
								fullWidth={true}
								value={this.state.password}
								onChange={this.handlePasswordChanged}
							/>
							<TextField
								type="password"
								placeholder={t("users.confirmPwd")}
								fullWidth={true}
								value={this.state.passwordConfirm}
								onChange={this.handlePasswordConfirmChanged}
							/>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<LoadingButton
							disabled={this.state.resetButtonDisabled}
							loading={this.state.resettingPwd}
							onClick={this.handleResetPasswordClick}
						>
							{t("messages.reset")}
						</LoadingButton>
						<Button color="inherit" onClick={this.handleCloseClick}>{t("dialog.cancel")}</Button>
					</DialogActions>
				</Dialog> */}
			</Card>
		);
	}

}

UserGeneralInfo.propTypes = {
	isAdvancedSearchEnabled: PropTypes.bool.isRequired,
	userId: PropTypes.string.isRequired,
	t: PropTypes.func.isRequired
};

export default withTranslation()(UserGeneralInfo);
