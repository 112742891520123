import { Component, createRef } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Tab, Paper } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// cmp
import withNavigateAndParams from "../withNavigateAndParams";
import UserGeneralInfo from "./user-general-info";
import UserGateways from "./UserGateways";
import UserOAuth2Tokens from "./user-oauth2-tokens";
import UserSockets from "./user-sockets";
import UserTrace from "./user-trace";
import UserTokens from "./user-tokens";
import UserLogs from "./user-logs";
// services
import Gupport from "../../services/gupport";
import Settings from "../../services/settings";
import { Storage, StorageKeys } from "../../services/storage";

const TAB_NAMES = {
	GENERAL: "general",
	GATEWAYS: "gateways",
	OAUTH2TOKENS: "oauth2tokens",
	SOCKETS: "sockets",
	TRACE: "trace",
	TOKENS: "tokens",
	LOGS: "logs"
};

class UserTabs extends Component {

	constructor(props) {
		super(props);

		this.hasTraceGlient = Boolean(Gupport.traceGlient);
		this.hasGetTokensAlb = Boolean(Gupport.getTokens);
		this.hasGetLogs = Boolean(Gupport.getUserLogs);

		let tab;
		if (this.props.params.tab) {
			Storage.set(StorageKeys.usersTab, this.props.params.tab);
			tab = this.props.params.tab;
		} else if (Storage.get(StorageKeys.usersTab)) {
			tab = Storage.get(StorageKeys.usersTab);
		} else {
			tab = TAB_NAMES.GENERAL;
		}

		this.state = {
			// ready: Gupport.ready,
			selectedTab: tab
		};

		this.references = {
			general: createRef(),
			gateways: null,
			oauth2tokens: null,
			sockets: createRef(),
			trace: createRef(),
			tokens: createRef(),
			logs: createRef()
		};
		this.timeout = null;

		this.refresh = this.refresh.bind(this);
		this.handleTabChanged = this.handleTabChanged.bind(this);
	}

	componentDidMount() {
		if (Settings.autoRefresh) {
			this.refresh();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.timeout) {
			globalThis.clearTimeout(this.timeout);
		}
		if (this.props.params.tab && this.props.params.tab !== prevProps.params.tab) {
			const tab = this.props.params.tab;
			Storage.set(StorageKeys.usersTab, tab);
			this.setState({
				selectedTab: tab
			}, () => {
				this.refresh();
			});
		}
	}

	componentWillUnmount() {
		if (this.timeout) {
			globalThis.clearTimeout(this.timeout);
		}
	}

	refresh() {
		this.timeout = setTimeout(() => {
			this.references[this.state.selectedTab]?.current?.refresh?.();
			this.refresh();
		}, Settings.autoRefreshDuration * 1000);
	}

	handleTabChanged(event, value) {
		if (this.timeout) {
			globalThis.clearTimeout(this.timeout);
		}
		this.props.navigate(`/users/${this.props.userId}/${value}`);
	}

	render() {
		const { t } = this.props;

		if (!this.props.userId) {
			return null;
		}

		if (this.props.params.userId === undefined || this.props.params.tab === undefined) {
			if ((!this.hasGetTokensAlb && this.state.selectedTab === "tokens") || (!this.hasGetLogs && this.state.selectedTab === "logs")) {
				return <Navigate to={`/users/${this.props.userId}/general`} replace={true} />;
			} else {
				return <Navigate to={`/users/${this.props.userId}/${this.state.selectedTab}`} replace={true} />;
			}
		}

		return (
			<TabContext value={this.state.selectedTab}>
				<Paper style={{ marginBottom: "12px" }}>
					<TabList onChange={this.handleTabChanged}>
						<Tab label={t("users.info")} value={TAB_NAMES.GENERAL} />
						<Tab label={t("users.gateways")} value={TAB_NAMES.GATEWAYS} />
						<Tab label={t("users.oauth2tokens.title")} value={TAB_NAMES.OAUTH2TOKENS} />
						<Tab label={t("users.sockets")} value={TAB_NAMES.SOCKETS} />
						{this.hasTraceGlient && <Tab label={t("users.trace.title")} value={TAB_NAMES.TRACE} />}
						{this.hasGetTokensAlb && <Tab label={t("users.pushTokens")} value={TAB_NAMES.TOKENS} />}
						{this.hasGetLogs && <Tab label={t("users.logs")} value={TAB_NAMES.LOGS} />}
					</TabList>
				</Paper>
				<TabPanel value={TAB_NAMES.GENERAL}><UserGeneralInfo ref={this.references.general} userId={this.props.userId} isAdvancedSearchEnabled={this.props.isAdvancedSearchEnabled} /></TabPanel>
				<TabPanel value={TAB_NAMES.GATEWAYS}><UserGateways userId={this.props.userId} name={this.props.name} /></TabPanel>
				<TabPanel value={TAB_NAMES.OAUTH2TOKENS}><UserOAuth2Tokens userId={this.props.userId} /></TabPanel>
				<TabPanel value={TAB_NAMES.SOCKETS}><UserSockets ref={this.references.sockets} userId={this.props.userId} /></TabPanel>
				{this.hasTraceGlient && <TabPanel value={TAB_NAMES.TRACE}><UserTrace ref={this.references.trace} userId={this.props.userId} /></TabPanel>}
				{this.hasGetTokensAlb && <TabPanel value={TAB_NAMES.TOKENS}><UserTokens ref={this.references.tokens} userId={this.props.userId} /></TabPanel>}
				{this.hasGetLogs && <TabPanel value={TAB_NAMES.LOGS}><UserLogs ref={this.references.logs} userId={this.props.userId} /></TabPanel>}
			</TabContext>
		);
	}

}

UserTabs.propTypes = {
	name: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	isAdvancedSearchEnabled: PropTypes.bool.isRequired,
	navigate: PropTypes.func.isRequired,
	params: PropTypes.shape({
		tab: PropTypes.string,
		userId: PropTypes.string
	}).isRequired,
	t: PropTypes.func.isRequired
};

export default withTranslation()(withNavigateAndParams(UserTabs));
