import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
	CircularProgress,
	Paper,
	List,
	ListItemButton,
	ListItemText,
	Divider
} from "@mui/material";
import { red } from "@mui/material/colors";
// cmp
import Terminal from "../terminal/terminal";
// services
import Gupport from "../../services/gupport";
import CC from "../../services/cc";
import Settings from "../../services/settings";

const GENERAL_TRACE = {
	sessionId: "general",
	logs: [],
	cc: null
};

class UserTrace extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// ready: Gupport.ready,
			loading: true,
			error: null,
			generalTrace: GENERAL_TRACE,
			traces: [],
			selectedTraceSessionId: "general"
		};

		this.handleGupportReady = this.handleGupportReady.bind(this);
		this.handleGupportMessage = this.handleGupportMessage.bind(this);
		this.handleLoadFile = this.handleLoadFile.bind(this);
	}

	componentDidMount() {
		Gupport.on("ready", this.handleGupportReady);
		Gupport.on("message", this.handleGupportMessage);

		this.traceGlient(this.props.userId);
	}

	componentWillUnmount() {
		Gupport.off("ready", this.handleGupportReady);
		Gupport.off("message", this.handleGupportMessage);

		this.disconnectAll();
	}

	handleGupportReady() {
		// this.setState({
		// 	ready: Gupport.ready
		// });
	}

	handleGupportMessage(msg) {
		if (msg.payload?.info === "subTrace") {
			switch (msg.payload.type) {
				case "createSession":
					this.createSession({
						...msg.payload.data,
						sessionId: msg.payload.sessionId,
						logs: [],
						cc: new CC({heartbeat: true})
					});
					break;
				case "closeSession":
					this.closeSession(msg.payload);
					break;
				default:
			}

			this.storeTraceToSession("general", {
				type: "rtrace",
				data: msg.payload.msg,
				ts: msg.payload.ts || new Date().toISOString(),
				dir: UserTrace.getDir(msg.payload)
			});
		}
	}

	storeTraceToSession(sessionId, log) {
		if (sessionId === "general") {
			this.setState((prevState) => ({
				generalTrace: {
					...prevState.generalTrace,
					logs: [...prevState.generalTrace.logs, log]
				}
			}));
		} else {
			this.setState((prevState) => ({
				traces: prevState.traces.map((trace) => (
					(trace.sessionId === sessionId) ? {...trace, logs: [...trace.logs, log]} : trace
				))
			}));
		}
	}

	handleLoadFile(file) {
		const fileReader = new FileReader();
		fileReader.readAsText(file, "UTF-8");
		fileReader.onload = (event) => {
			try {
				const trace = JSON.parse(event.target?.result);
				this.setState((prevState) => ({
					traces: [...prevState.traces, trace]
				}));
			} catch (error) {
				console.info("Error converting object to string");
			}
		};
	}

	createSession(trace) {
		this.connect(trace);
		this.setState((prevState) => ({
			traces: [...prevState.traces, trace]
		}));
	}

	closeSession(payload) {
		this.setState((prevState) => ({
			traces: prevState.traces.map((trace) => {
				if (trace.sessionId === payload.sessionId) {
					this.disconnect(trace);
					return {
						...trace,
						logs: [...trace.logs, {type: "rtrace", data: payload.msg}],
						closed: true
					};
				}
				return trace;
			})
		}));
	}

	traceGlient(userId) {
		Gupport.traceGlient({
			username: userId
		}, (error, msg) => {
			if (error || msg.payload.status === "error") {
				this.setState({
					loading: false,
					error: msg.payload.data,
					traces: []
				});
			} else {
				this.setState({
					loading: false,
					error: null,
					traces: msg.payload.data.map((trace) => ({
						...trace,
						logs: [],
						cc: new CC({heartbeat: true})
					}))
				}, () => {
					this.state.traces.forEach((trace) => {
						this.connect(trace);
					});
				});
			}
		});
	}

	handleTraceClick(sessionId) {
		if (this.state.selectedTraceSessionId !== sessionId) {
			this.setState({
				selectedTraceSessionId: sessionId
			});
		}
	}

	connect(trace) {
		trace.cc.setOptions({
			url: Settings.cluster.ccWsUrl,
			login: {
				action: "login",
				sessionId: trace.sessionId
			},
			heartbeat: true,
			channel: Settings.channel
		});
		if (trace.cc.ready) {
			this.onReady(trace);
		} else {
			trace.cc.once("ready", () => {
				this.onReady(trace);
			});
		}
	}

	onReady(trace) {
		if (trace.cc.disconnecting) {
			trace.cc.once("disconnected", () => {
				this.doConnect(trace);
			});
		} else {
			this.doConnect(trace);
		}
	}

	doConnect(trace) {
		if (!trace.cc.connecting) {
			trace.cc.on("message", this.handleCCMessage.bind(this, trace.sessionId));
			trace.cc.connect();
		}
	}

	disconnect(trace, reconnect = false) {
		if (trace.cc.connected) {
			if (!trace.cc.disconnecting) {
				if (reconnect) { // Workaround for tab unmount/mount problem
					trace.cc.once("disconnected", () => {
						this.connect(trace);
					});
				}
				trace.cc.off("message", this.handleCCMessage.bind(this, trace.sessionId));
				trace.cc.disconnect();
			}
		}
	}

	disconnectAll() {
		this.state.traces.forEach((trace) => {
			this.disconnect(trace);
		});
	}

	handleCCMessage(sessionId, msg) {
		if (msg.payload && msg.payload.data && msg.payload.action === "jsonTrace" && msg.payload.module !== "shell") {
			this.storeTraceToSession(sessionId, {
				type: msg.payload.action,
				data: msg.payload.data,
				ts: msg.payload.ts || new Date().toISOString(),
				dir: UserTrace.getDir(msg.payload)
			});
		}
	}

	static getDir(payload) {
		if (payload.dir) {
			return payload.dir;
		}
		if (!payload.data) {
			return null;
		}
		if (payload.data.action) {
			return "tx";
		}
		if (payload.data.info) {
			return "rx";
		}
		return null;
	}

	render() {
		const { t } = this.props;
		if (this.state.loading) {
			return <CircularProgress />;
		}
		if (this.state.error) {
			return <div>{this.state.error}</div>;
		}

		const selectedTrace = (this.state.selectedTraceSessionId === "general") ? this.state.generalTrace : this.state.traces.find((trace) => (trace.sessionId === this.state.selectedTraceSessionId));

		return (
			<div style={{ display: "grid", gridTemplateColumns: "360px auto", columnGap: "16px" }}>
				<Paper style={{ overflowY: "auto", height: "calc(100vh - 156px)" }}>
					<List>
						<ListItemButton
							selected={this.state.selectedTraceSessionId === "general"}
							onClick={this.handleTraceClick.bind(this, "general")}
						>
							<ListItemText primary={t("users.trace.general")} />
						</ListItemButton>
						{this.state.traces.map((trace, index) => (
							<Fragment key={trace.sessionId}>
								{(index > 0) && <Divider />}
								<ListItemButton
									selected={this.state.selectedTraceSessionId === trace.sessionId}
									onClick={this.handleTraceClick.bind(this, trace.sessionId)}
									style={trace.closed ? {backgroundColor: red[200]} : {}}
								>
									<ListItemText
										primary={trace.sessionId}
										secondary={trace.connected || trace.created}
										primaryTypographyProps={{ noWrap: true }}
										secondaryTypographyProps={{ noWrap: true }}
									/>
								</ListItemButton>
							</Fragment>
						))}
					</List>
				</Paper>
				<Paper>
					{selectedTrace &&
						<Terminal
							cc={selectedTrace.cc}
							module="ccc"
							readOnly={true}
							userId={this.props.userId}
							traces={[this.state.generalTrace, ...this.state.traces]}
							terminalEntries={selectedTrace.logs}
							sessionId={selectedTrace.sessionId}
							onLoadFile={this.handleLoadFile}
							style={{borderRadius: "4px"}}
						/>
					}
				</Paper>
			</div>
		);
	}

}

UserTrace.propTypes = {
	userId: PropTypes.string.isRequired,
	t: PropTypes.func.isRequired
};

export default withTranslation()(UserTrace);
