import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
// cmp
import Na from "../na";
// services
import Gupport from "../../services/gupport";
import Constants from "../../services/constants";
// types
import type { GridColDef } from "@mui/x-data-grid";
import type { ValueOf } from "type-fest";
import type { CmdCloudActionCloud2Cloud } from "../../types/message";

type OAuth2Tokens = Array<OAuth2Token>;
interface OAuth2Token {
	id: string;
	integration_id: string;
	type: ValueOf<typeof Constants.GatewayModules>;
	expires_at: number;
	expires_in: number;
	link_ts: string | null;
	out_of_sync: boolean;
	gateways: Array<string>;
}

type Props = {
	userId: string;
};

const UserOAuth2Tokens = (props: Props) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [refreshing, setRefreshing] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [tokens, setTokens] = useState<OAuth2Tokens>([]);

	useEffect(() => {
		if (Gupport.getUserC2C) {
			Gupport.getUserC2C({
				userId: props.userId
			}, (error, msg) => {
				if (!error && msg.payload.status === "ok") {
					setError(null);
					setTokens(msg.payload.data);
				} else {
					setError(error?.message ?? msg.payload.data);
					setTokens([]);
				}
				setLoading(false);
			});
		} else {
			setLoading(false);
			setError(t("users.lError"));
			setTokens([]);
		}
	}, [props.userId]);

	const handleModuleRefresh = (moduleId: string) => {
		setRefreshing(true);
		const cmd: CmdCloudActionCloud2Cloud = {
			action: "cloudAction",
			module: "cloud2cloud",
			function: "refresh_devices",
			params: {
				id: moduleId
			}
		};
		Gupport.send(cmd, (/*error, msg*/) => {
			setRefreshing(false);
		});
	};

	if (error) {
		return <div>{error}</div>;
	}

	const columns: Array<GridColDef<OAuth2Token>> = [
		{
			field: "id",
			headerName: t("users.oauth2tokens.columns.id.title"),
			description: t("users.oauth2tokens.columns.id.tooltip"),
			flex: 3
		},
		{
			field: "integration_id",
			headerName: t("users.oauth2tokens.columns.integration_id.title"),
			description: t("users.oauth2tokens.columns.integration_id.tooltip"),
			flex: 3
		},
		{
			field: "expires_at",
			headerName: t("users.oauth2tokens.columns.expires_at.title"),
			description: t("users.oauth2tokens.columns.expires_at.tooltip"),
			flex: 2,
			renderCell: (params) => (<time dateTime={new Date(params.value * 1000).toISOString()}>{new Date(params.value * 1000).toLocaleString()}</time>)
		},
		{
			field: "expires_in",
			headerName: t("users.oauth2tokens.columns.expires_in.title"),
			description: t("users.oauth2tokens.columns.expires_in.tooltip"),
			type: "number",
			flex: 1
		},
		{
			field: "link_ts",
			headerName: t("users.oauth2tokens.columns.link_ts.title"),
			description: t("users.oauth2tokens.columns.link_ts.tooltip"),
			flex: 2,
			renderCell: (params) => (params.value || <Na />)
		},
		{
			field: "out_of_sync",
			headerName: t("users.oauth2tokens.columns.out_of_sync.title"),
			description: t("users.oauth2tokens.columns.out_of_sync.tooltip"),
			flex: 1
		},
		{
			field: "type",
			headerName: t("users.oauth2tokens.columns.type.title"),
			description: t("users.oauth2tokens.columns.type.tooltip"),
			type: "singleSelect",
			valueOptions: Object.values(Constants.GatewayModules),
			flex: 1
		},
		{
			field: "gateways",
			headerName: t("users.oauth2tokens.columns.gateways.title"),
			description: t("users.oauth2tokens.columns.gateways.tooltip"),
			flex: 3,
			valueFormatter: (value) => (value.join(", "))
		},
		{
			field: "actions",
			headerName: t("users.oauth2tokens.columns.actions.title"),
			description: t("users.oauth2tokens.columns.actions.tooltip"),
			flex: 2,
			renderCell: (params) => (
				(params.row.type === Constants.GatewayModules.tuya) ?
					<LoadingButton
						loading={refreshing}
						onClick={() => (handleModuleRefresh(params.row.id))}
					>
						{t("users.oauth2tokens.columns.actions.refresh")}
					</LoadingButton>
					:
					null
			)
		}
	];

	return (
		<Paper>
			<DataGrid
				loading={loading}
				columns={columns}
				rows={tokens}
			/>
		</Paper>
	);
};

UserOAuth2Tokens.propTypes = {
	userId: PropTypes.string.isRequired
};

export default UserOAuth2Tokens;
